/* nav bar */
.ant-anchor-wrapper{
  position: fixed;
  top: 60px;
  background-color: transparent !important;
  width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: unset !important;
  left: 0;
  right: 0;
}

.navigation-mobile{
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  width: 90% !important;
  z-index: 10;
}

@media screen and (max-height: 800px) {
  .ant-anchor-wrapper{ top: 20px; }
}
@media screen and (max-width: 400px) {
  .ant-anchor-wrapper{ top: 20px; }
}

.ant-anchor, .navigation-mobile{
  margin: auto !important;
  display: flex;
  width: 70%;
  height: 6rem;
  padding: 0 5% !important;
  transition: padding 0.2s;
  font-family: 'Montserrat', sans-serif;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1300px) {
  .ant-anchor{ width: 80%; }
}
@media screen and (max-width: 1000px) {
  .ant-anchor{ width: 90%; }
}

.ant-affix .ant-anchor, .navigation-mobile {
  background-color: white;
  height: 5rem;
  border-radius: 2.5rem;
  padding: 0.5rem 5% !important;
  margin: 0.5rem auto !important;
  box-shadow: 4px 1px 10px 1px #0000003f;
}

.ant-anchor h1, .navigation-mobile h1{
  color: var(--beige);
  font-size: 6rem;
  transition: all 0.2s;
  margin: 0;
  display: flex;
  align-items: center;
  font-family: 'Glyphter';
}

.ant-affix h1, .navigation-mobile h1 {
  font-size: 4rem;
  color: var(--primary);
}

.ant-anchor .menu{
  display: flex;
  margin: 0 -2rem;
}

.ant-anchor .anticon, .navigation-mobile .anticon{
  font-size: 3rem;
  padding: 0.5rem;
  color: white;
}

.ant-affix .anticon, .navigation-mobile .anticon{
  color: var(--primary);
  cursor: pointer;
}

.ant-anchor-ink{
  display: none;
}

.ant-anchor-link {
  padding: 0 !important;
  display: flex;
  align-items: center;
  margin: 0 2rem;
}

.ant-anchor-link-title {
  font-size: 1.6rem;
  color: white !important;
}

.ant-anchor-link-title:hover, .ant-anchor-link-title-active {
  font-weight: bold;
}

.ant-anchor-link-title-active{
  border-bottom: 2px solid white;
}

.ant-affix .ant-anchor-link-title {
  color: var(--dark-gray) !important;
}

.ant-affix .ant-anchor-link-title-active{
  border-bottom: 2px solid var(--primary);
}

.mobile-menu h1{
  font-size: 6rem;
  line-height: 5rem;
  color: var(--primary);
}

.mobile-menu .ant-anchor-wrapper {
  position: unset;
  width: auto !important;
}

.mobile-menu .ant-anchor{
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mobile-menu .ant-anchor-link{
  margin: 0;
  margin-top: 2rem;
}

.mobile-menu .ant-anchor-link-title-active{
  border-color: var(--primary) !important;
}

.mobile-menu .ant-anchor-link-title {
  font-size: 2rem;
  color: black !important;
  border-bottom: 2px solid white;
}

/* landing */
.landing {
  display: flex;
}
@media screen and (max-width: 800px) {
  .landing{
    flex-direction: column;
    margin-top: 80px !important;
    max-width: 400px;
    margin: auto;
  }

  .landing .text, .landing .logo{
    width: 100% !important;
  }

  .slogan {
    width: 70vw !important;
  }
}

.landing .logo {
  margin-bottom: 2rem;
  margin-top: 4rem;
}

.landing .text, .landing .logo{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: center;
}

.landing h1 {
  font-size: 5.3rem;
  line-height: 5rem;
  font-family: 'Galano Grotesque Black';
  color: white;
  margin-bottom: 6rem;
}

.landing h2 {
  font-size: 2rem;
}

.landing a {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  background-color: white;
  color: var(--primary);
  font-weight: bold;
  font-size: 1.6rem;
  width: 20rem;
  margin-top: 3rem;
}

.landing a:hover{
  color: var(--primary);
}

.slogan {
  max-width: 464px;
  width: 40vw;
}
