@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

@font-face {
  font-family: 'Galano Grotesque';
  src: url('./Assets/Fonts/GalanoGrotesque/GalanoGrotesque.ttf');
}

@font-face {
  font-family: 'Galano Grotesque Black';
  src: url('./Assets/Fonts/GalanoGrotesque/GalanoGrotesqueBlack.ttf');
}

@font-face {
  font-family: 'Galano Grotesque Semi Bold';
  src: url('./Assets/Fonts/GalanoGrotesque/GalanoGrotesqueSemiBold.ttf');
}

@font-face {
  font-family: 'Galano Grotesque Medium';
  src: url('./Assets/Fonts/GalanoGrotesque/GalanoGrotesqueMedium.ttf');
}

/* Generated by Glyphter (http://www.glyphter.com) on  Thu Dec 19 2019*/
@font-face {
  font-family: 'Glyphter';
  src: url('./Assets/Fonts/Glyphter/Glyphter.ttf');
}

[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-ic_logo_giv:before{content:'\0041';}

:root {
  --primary: #E31250;
  --secondary: #28B595;
  --beige: #ECECDF;
  --ligth: #F6F9FB;
  --dark: #3A364E;
  --dark-gray: #6F6F6F;
  --medium-gray: #95A1AC;
  --mild-gray: #848484;
  --light-gray: #EFEFE3;
  --smoke-gray: #BFBFBF;
}

html { 
  font-size: 62.5%; 
}
@media screen and (max-width: 1600px) {
  html{ font-size: 56.25%; }
}
@media screen and (max-width: 900px) {
  html{ font-size: 50% }
}
@media screen and (max-width: 600px) {
  html{ font-size: 45%; }
}

/* layout */
body{
  overflow-x: hidden;
  overflow-y: scroll;
}

section{
  min-height: 90vh;
  position: relative;
  display: flex !important;
  font-family: 'Galano Grotesque Semi Bold';
}
section, .info{
  padding: 5% 20%;
}

@media screen and (max-width: 2300px) {
  section, .info{ padding: 5% 15%; }
}
@media screen and (max-width: 1300px) {
  section, .info{ padding: 5% 10%; }
}
@media screen and (max-width: 1000px) {
  section, .info{ padding: 5% 5%; }
}

#home, #borrow{
  background-color: var(--primary);
}

#overview, #comments{
  background-color: var(--ligth);
}

#greetings, #method{
  background-color: var(--secondary);
}

#home {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-back-top {
  box-shadow: 1px 1px 40px #00000050;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
}

.custom-select .ant-select-selection, .custom-input .ant-input {
  width: 100%;
  font-size: 2.5rem;
  height: 4.5rem;
  border: none;
  border-radius: unset;
  box-shadow: none !important;
  background: transparent;
  border-bottom: 2px solid #ffffff7F !important;
  color: white;
}

.custom-datepicker .ant-input{
    width: 100%;
    font-size: 2.5rem;
    height: 4.5rem;
    max-width: 280px;
    border: none;
    border-radius: unset;
    box-shadow: none !important;
    background: transparent;
    border-bottom: 2px solid #ffffff7F !important;
    color: white;
}

.custom-datepicker {
    margin-top: 5rem !important;
}

.ant-calendar-picker-container .ant-calendar {
    border: unset;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap {
    height: 4.5rem;
    background-color: var(--primary);
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input {
    background-color: var(--primary);
    color: #ffffff7F;
    font-size: 20px;
    height: 27px;
    font-weight: bold;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input::placeholder {
    background-color: var(--primary);
    color: white;
    opacity: 0.5;
    font-weight: bold;
}

.ant-calendar-selected-day .ant-calendar-date {
    background: #bae7ff;
}

.ant-calendar-picker .ant-calendar-picker-icon {
    color: white;
}
.custom-datepicker { 
    position: relative; 
    display: flex;
    flex-direction: column;
}
  
.custom-datepicker h4 {
    position: absolute;
    bottom: 4rem;
    font-size: 1.6rem;
    color: white;
    opacity: 0;
    margin: 0;
    transition: opacity 0.5s;
}
  
.custom-datepicker h4.visible{
    opacity: 0.5;
}
  
.custom-datepicker .ant-input { 
    padding-bottom: 1.5rem; 
    padding-left: 0;
}
  
.custom-datepicker .ant-input::placeholder {
    color: white;
    opacity: 0.5;
}

.custom-select .ant-select-selection__placeholder {
  height: 3rem;
  color: white;
  opacity: 0.5;
}

.custom-select {
  max-width: 500px;
}

.custom-button{
  background-color: white;
  border-radius: 2.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: var(--primary);
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.5s;
}

.custom-button:disabled{
  cursor: default;
  background-color: #ffffff7F;
}

.custom-select, .custom-input, .custom-button {
  margin-top: 5rem !important;
  width: 100%;
}

.ant-select-dropdown-menu-item{
  font-family: "Galano Grotesque";
  font-size: 2rem !important;
  line-height: 3rem !important;
}

.ant-select-dropdown-menu-item-active{
  background-color: #E312507f !important;
}

.custom-input { 
  position: relative; 
  display: flex;
  flex-direction: column;
}

.custom-input h4 {
  position: absolute;
  bottom: 4rem;
  font-size: 1.6rem;
  color: white;
  opacity: 0;
  margin: 0;
  transition: opacity 0.5s;
}

.custom-input h4.visible{
  opacity: 0.5;
}

.custom-input .ant-input { 
  padding-bottom: 1.5rem; 
  padding-left: 0;
}

.custom-input .ant-input::placeholder {
  color: white;
  opacity: 0.5;
}

.ant-tooltip {
  max-width: 100% !important;
}

.ant-tooltip-arrow::before {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.ant-tooltip-inner {
  color: #FE0100 !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  font-family: "Galano Grotesque";
  font-size: 1.5rem;
  padding: 1rem !important;
  padding-bottom: 1.2rem !important;
}

.ant-tooltip-content {
  border-radius: 0.5rem;
}

.ant-select-selection__rendered{
  margin: 0 !important;
}

.check .ant-checkbox-inner{
  --antd-wave-shadow-color: white;
  background-color: var(--primary);
  border: 2px solid white !important;
  width: 3.2rem;
  height: 3.2rem;
}

.check .ant-checkbox-checked .ant-checkbox-inner::after {
  border-radius: 0 0 3px 0;
  border-width: 0.4rem;
  border-color: var(--primary);
}

.check .ant-checkbox-checked .ant-checkbox-inner{
  background-color: white;
  border-color: var(--primary);
}

.check .ant-checkbox-inner::after{
  width: 1.2rem;
  height: 2.1rem;
}

.check .ant-checkbox-checked::after {
  border: none;
}