.company h3{
  color: #FFFFFF;
  font-family: "Galano Grotesque";
  opacity: 0.5;
  font-size: 1.4rem;
  margin-top: 1rem;
}

.company h2 {
  margin-top: 5rem;
  margin-bottom: 0;
  color: white;
  font-size: 3rem;
}

.company .error {
    position: absolute;
    max-width: 87rem;
}

.company .error .arrow-up {
    position: relative;
    left: 16px;
    margin-top: 1rem;
    width: 0; 
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    
    border-bottom: 4px solid rgba(255, 255, 255, 0.75);

}
.company .error .error-message {
  background-color: rgba(255, 255, 255, 0.95);
  color: #FE0100;
  font-family: "Galano Grotesque";
  font-size: 1.8rem;
  border-radius: 4px;
  padding: 1rem;
}