.policy-and-terms{
  font-family: 'Montserrat';
  width: 60vw !important;
}
@media screen and (max-width: 2300px) {
  .policy-and-terms{ width: 70vw !important; }
}
@media screen and (max-width: 1300px) {
  .policy-and-terms{ width: 80vw !important; }
}
@media screen and (max-width: 1000px) {
  .policy-and-terms{ width: 90vw !important; }
}

.policy-and-terms .ant-modal-footer{
  display: none;
}

.policy-and-terms h1 {
  text-align: center;
}

.policy-and-terms h1,
.policy-and-terms h2,
.policy-and-terms h3 {
  font-weight: bold;
}

.policy-and-terms .custom-button{
  font-family: "Galano Grotesque";
  max-width: 20rem;
  background-color: var(--primary);
  color: white;
  margin: 0 auto;
}