#comments {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 5%;
}

#comments .header {
    align-self: center;
    padding-top: 6rem;
}

#comments .title {
  width: 30vw;
  min-width: 350px;
  max-width: 380px;
}

#comments .steps {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 14rem;
}

@media screen and (max-width: 600px) {
  #comments .steps{ 
    margin-top: 4rem; 
    margin-bottom: 9rem;
    
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr 1fr;
  }

  #comments .steps .step p{
    height: 110px;
  }
  
#comments .title {
    min-width: 270px;
  }
  #comments .header {
    padding-top: 6rem;
    }
}

@media screen and (max-width: 800px) {      
  #comments .header {
    padding-top: 12rem;
    }
  }
@media screen and (min-width: 1400px) {
    
  #comments .header {
    padding-top: 2rem;
    }
  }
  
#comments .steps .step {
  background-color: #FFFFFF90;
  box-shadow: 1px 1px 40px #70707029;
  border-radius: 2.3rem;
  padding: 3.5rem;
  display: grid;
  grid-template-areas:
    "comment"
    "name"
    "location";
  grid-template-columns: auto 1fr;
  max-width: 40rem;
  margin: 0 auto;
}

#comments h2, #comments h3{
  font-size: 2.5rem;
  margin: 0;
  z-index: 2;
  line-height: 3rem;
}

#comments h2{
  margin: 2rem 0 4rem 0;
  max-width: 50vw;
}
@media screen and (max-width: 750px) {
  #comments h2 { max-width: 30vw; }
}

#comments .step h3{
  font-size: 2rem;
  color: var(--primary);
  text-align: left;
  margin-bottom: 3px;
  line-height: 2.2rem;
  grid-area: name;
}

#comments .step p{
  font-size: 1.8rem;
  line-height: 2.2rem;
  height: 170px;
  margin-bottom: 15px;
  color: black;
  margin: 0;
  font-family: 'Galano Grotesque';
  grid-area: comment;
}

#comments .step h4 {
    color: var(--dark);
    font-size: 1.6rem;
    grid-area: location;
  }