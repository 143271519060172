/* greetings */
#greetings .background {
  position: absolute;
  bottom: 0;
  left: -12%;
  height: 100%;
}
@media screen and (min-width: 1800px) {
  #greetings .background{ left: 0 !important; }
}
@media screen and (max-height: 760px) {
  #greetings .background{ left: 0 !important; }
}
@media screen and (max-width: 1400px) {
  #greetings .background{ left: -12% !important; }
}
@media screen and (max-width: 1100px) {
  #greetings .background{ left: -22% !important; }
}

#greetings .background-mobile {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}
@media screen and (max-width: 400px) {
  #greetings .background-mobile{ left: -12%; }
}

#greetings .background-mobile img {
  width: 100%;
  min-width: 600px;
}

#greetings .mobile-gradient {
  height: 120px;
  bottom: 0;
  position: absolute;
  left: 0;
  right: -12%;
  background-image: linear-gradient( transparent, var(--secondary));
}

#greetings .description {
  width: 50%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#greetings{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .description{
    width: 100% !important;
    min-height: 80vh;
    padding-top: 320px;
  }

  #overview{ flex-direction: column; }

  #overview .texts {
    padding-right: 0 !important;
    width: 100% !important;
    margin-bottom: 3rem;
  }
}

.description img {
  margin-bottom: 3rem;
  width: 30vw;
  min-width: 200px;
  max-width: 260px;
}

.description h2 {
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 3rem;
  color: white;
}

.description p {
  font-family: 'Galano Grotesque';
  font-size: 2rem;
  margin-bottom: 0;
  color: black;
}

/* overview */
#overview{
  align-items: center;
}

#overview .texts{
  padding-right: 10%;
  width: 50%;
  height: 100%;
}

#overview .texts h1{
  font-family: 'Galano Grotesque Black';
  font-size: 5.3rem;
  line-height: 5rem;
  color: var(--primary);
  margin-bottom: 3rem;
}

#overview .texts h2{
  font-size: 2.5rem;
  color: black;
  margin-bottom: 2rem;
}

#overview .texts a {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  color: white;
  background-color: var(--primary);
  font-size: 1.6rem;
  width: 20rem;
  margin-top: 3rem;
}

#overview .cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.cards .card{
  box-shadow: 1px 1px 40px #70707029;
  border-radius: 1.7rem;
  background-color: white;
  padding: 3.5rem;
  width: 100%;
}
@media screen and (max-width: 400px) {
  .cards .card{ padding: 2rem; }
}

.card img {
  height: 6.2rem;
  width: 6.2rem;
  margin-bottom: 2rem;
}

.card h4 {
  color: var(--dark);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.card p {
  font-family: 'Galano Grotesque';
  color: var(--mild-gray);
  font-size: 1.6rem;
  margin: 0;
}

.cards .image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards .image img {
  width: 80%;
}