.copyright {
  height: 6rem;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  padding: 0 2rem;
}

.copyright h4{
  color: var(--medium-gray);
  margin: 0;
  font-size: 1.3rem;
}

.info{
  padding: 70px;
  display: flex;
  flex-direction: column;
  font-family: 'Galano Grotesque Semi Bold';
}
@media screen and (max-width: 900px) {
  .info{ 
    padding: 40px 20px;
  }
}
@media screen and (max-width: 400px) {
  .info{ 
    grid-template-columns: 1fr; 
  }
}

.info .busines{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .info .busines{
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .info .busines{
    flex-direction: column;
    justify-content: space-between;
  }

  .info article h3 {
    font-size: 4rem !important;
  }
  
  .info article h4 {
    font-size: 2rem !important;
  }
  
  .info article h5 {
    font-size: 1.8rem !important;
  }
}

.info article h3 {
  font-size: 3rem;
  color: var(--primary);
  margin: 0;
  font-family: "Galano Grotesque Semi Bold";
}

.info article h4 {
  font-size: 1.8rem;
  color: black;
  margin-bottom: 4rem;
}

.info article h5 {
  font-size: 1.4rem;
  color: var(--dark-gray);
  margin-bottom: 1rem;
}

.info article h5, .info .social h5{
  font-size: 1.4rem;
  color: var(--dark-gray);
  margin-bottom: 1rem;
}

.info article p {
  font-family: 'Galano Grotesque';
  align-items: flex-start;
  color: black;
}

.info article p img {
  margin-top: 0.4rem;
  width: 2rem;
  height: 1.6rem;
  margin-right: 1rem;
}

.info .social {
  display: flex;
  flex-direction: column;
}

.info .social .buttons{
  display: flex;
}

.info .social .buttons a:not(:last-child){
  margin-right: 2rem;
}

.info .social .buttons img{
  width: 30px;
  height: 30px;
}

.custom-modal .ant-modal-content{
  border-radius: 1rem;
}

.custom-modal .ant-modal-header{
  border: none;
  padding: 3rem 3rem 0 3rem;
  border-radius: 1rem;
}

.custom-modal .ant-modal-title{
  color: var(--primary);
  font-size: 3rem;
  font-family: "Galano Grotesque Semi Bold";
  line-height: 3rem;
}

.custom-modal .ant-modal-body{
  padding: 3rem;
}

.custom-modal .ant-modal-body h3 {
  margin: 0;
  font-size: 2rem;
  font-family: "Galano Grotesque Semi Bold";
}

.custom-modal .ant-modal-body p {
  font-size: 1.6rem;
  font-family: "Galano Grotesque";
}

.custom-modal .ant-modal-footer{
  display: none;
}

.copyright .terms-and-policy {
  cursor: pointer;
  width: 50%;
  justify-content: center;
  display: flex;
  margin-left: auto;
}

.company-info p{
  color: #6F6F6F !important;
  font-size: 12px;
}

.busines {
  margin-bottom: 3rem;
}

.lock {
  margin-bottom: 3rem;
}