.done .form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.done .form-title img {
  height: 16rem;
  width: 16rem;
  margin-bottom: 2rem;
}

.done h2 {
  font-size: 2.5rem;
  text-align: center;
}