.confirm h2 {
  color: black;
  font-size: 1.8rem;
}

.confirm .card {
  margin-top: 5rem;
  box-shadow: 1px 1px 40px 1px #00000050;
  background-color: white;
  border-radius: 2.3rem;
  padding: 3.5rem;
  display: flex;
  margin-bottom: 4rem;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .confirm .card {
    flex-direction: column;
  }

  .confirm .card .summary{
    width: unset !important;
    padding-right: 0 !important;
    border-bottom: 1px solid var(--smoke-gray);
    margin-bottom: 2rem;
  }

  .confim .card .confirm {
    width: unset !important;
    padding-left: 0 !important;
    margin: auto;
  }

  .confirm .card .installments {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.confirm .card .data {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.confirm .card .data h5, .confirm .card .data h4 {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: var(--primary);
  padding: 1rem 0;
}

.confirm .card .data h5:not(:last-of-type), .confirm .card .data h4:not(:last-of-type){
  border-bottom: 1px solid var(--smoke-gray);
}

.confirm .card .data h5 {
  justify-content: flex-end;
  padding-right: 2rem;
  font-size: 1.6rem;
  color: black;
  font-family: "Galano Grotesque";
}

.confirm .card .summary h3 {
  font-size: 2.4rem;
}

.confirm .card .installments {
  width: 100%;
  border-bottom: 1px solid var(--smoke-gray);
}

.confirm .card .installments h5 {
  font-size: 1.6rem;
  font-family: "Galano Grotesque";
  color: black;
  margin-bottom: 0.5rem;
}

.confirm .card .installments h4 {
  color: var(--primary);
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

.confirm .card .observation{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.confirm .card .observation p {
  margin-top: 2rem;
  font-size: 1.2rem;
  max-width: 250px;
}

.confirm .check{
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.confirm .check h3{
  margin-bottom: 0;
  margin-left: 1.6rem;
  font-family: "Galano Grotesque";
  color: white;
  font-size: 1.6rem;
}

.confirm .check h3 span{
  color: white;
  text-decoration: underline;
  cursor: pointer;
}