/* #method */
#method{
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 5%;
}

#method .how {
  width: 30vw;
  min-width: 200px;
  max-width: 260px;
}

#method .background {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 90%;
}

#method .steps{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 4rem;
}

@media screen and (max-width: 600px) {
  #method .steps{ 
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr 1fr; 
  }

  #method .background { height: 50%;}

  #method h2 { max-width: unset !important; }

  #method .steps .step {
    grid-template-areas:
      "number title"
      "text text";
  }
}

#method .steps .step {
  background-color: #FFFFFF90;
  box-shadow: 1px 1px 40px #70707029;
  border-radius: 2.3rem;
  z-index: 1;
  padding: 3.5rem;
  display: grid;
  grid-template-areas:
    "number"
    "title"
    "text";
  grid-template-columns: auto 1fr;
  max-width: 40rem;
  margin: 0 auto;
}

#method h2, #method h3{
  font-size: 2.5rem;
  margin: 0;
  z-index: 2;
  line-height: 3rem;
}

#method h2{
  margin: 2rem 0 4rem 0;
  max-width: 50vw;
}
@media screen and (max-width: 750px) {
  #method h2 { max-width: 30vw; }
}

#method h3{
  color: white;
  width: 100%;
  text-align: center;
}

#method .step h1{
  height: 6.2rem;
  width: 6.2rem;
  border-radius: 50%;
  color: white;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 6rem;
  line-height: 6.2rem;
  margin-right: 3rem;
  grid-area: number;
}

#method .step h3{
  font-size: 2rem;
  color: var(--primary);
  text-align: left;
  margin-bottom: 1.5rem;
  line-height: 2.2rem;
  grid-area: title;
}

#method .step p{
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: black;
  margin: 0;
  font-family: 'Galano Grotesque';
  grid-area: text;
}