@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
/* nav bar */
.ant-anchor-wrapper{
  position: fixed;
  top: 60px;
  background-color: transparent !important;
  width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: unset !important;
  left: 0;
  right: 0;
}

.navigation-mobile{
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  width: 90% !important;
  z-index: 10;
}

@media screen and (max-height: 800px) {
  .ant-anchor-wrapper{ top: 20px; }
}
@media screen and (max-width: 400px) {
  .ant-anchor-wrapper{ top: 20px; }
}

.ant-anchor, .navigation-mobile{
  margin: auto !important;
  display: flex;
  width: 70%;
  height: 6rem;
  padding: 0 5% !important;
  -webkit-transition: padding 0.2s;
  transition: padding 0.2s;
  font-family: 'Montserrat', sans-serif;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1300px) {
  .ant-anchor{ width: 80%; }
}
@media screen and (max-width: 1000px) {
  .ant-anchor{ width: 90%; }
}

.ant-affix .ant-anchor, .navigation-mobile {
  background-color: white;
  height: 5rem;
  border-radius: 2.5rem;
  padding: 0.5rem 5% !important;
  margin: 0.5rem auto !important;
  box-shadow: 4px 1px 10px 1px #0000003f;
}

.ant-anchor h1, .navigation-mobile h1{
  color: var(--beige);
  font-size: 6rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0;
  display: flex;
  align-items: center;
  font-family: 'Glyphter';
}

.ant-affix h1, .navigation-mobile h1 {
  font-size: 4rem;
  color: var(--primary);
}

.ant-anchor .menu{
  display: flex;
  margin: 0 -2rem;
}

.ant-anchor .anticon, .navigation-mobile .anticon{
  font-size: 3rem;
  padding: 0.5rem;
  color: white;
}

.ant-affix .anticon, .navigation-mobile .anticon{
  color: var(--primary);
  cursor: pointer;
}

.ant-anchor-ink{
  display: none;
}

.ant-anchor-link {
  padding: 0 !important;
  display: flex;
  align-items: center;
  margin: 0 2rem;
}

.ant-anchor-link-title {
  font-size: 1.6rem;
  color: white !important;
}

.ant-anchor-link-title:hover, .ant-anchor-link-title-active {
  font-weight: bold;
}

.ant-anchor-link-title-active{
  border-bottom: 2px solid white;
}

.ant-affix .ant-anchor-link-title {
  color: var(--dark-gray) !important;
}

.ant-affix .ant-anchor-link-title-active{
  border-bottom: 2px solid var(--primary);
}

.mobile-menu h1{
  font-size: 6rem;
  line-height: 5rem;
  color: var(--primary);
}

.mobile-menu .ant-anchor-wrapper {
  position: unset;
  width: auto !important;
}

.mobile-menu .ant-anchor{
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mobile-menu .ant-anchor-link{
  margin: 0;
  margin-top: 2rem;
}

.mobile-menu .ant-anchor-link-title-active{
  border-color: var(--primary) !important;
}

.mobile-menu .ant-anchor-link-title {
  font-size: 2rem;
  color: black !important;
  border-bottom: 2px solid white;
}

/* landing */
.landing {
  display: flex;
}
@media screen and (max-width: 800px) {
  .landing{
    flex-direction: column;
    margin-top: 80px !important;
    max-width: 400px;
    margin: auto;
  }

  .landing .text, .landing .logo{
    width: 100% !important;
  }

  .slogan {
    width: 70vw !important;
  }
}

.landing .logo {
  margin-bottom: 2rem;
  margin-top: 4rem;
}

.landing .text, .landing .logo{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: center;
}

.landing h1 {
  font-size: 5.3rem;
  line-height: 5rem;
  font-family: 'Galano Grotesque Black';
  color: white;
  margin-bottom: 6rem;
}

.landing h2 {
  font-size: 2rem;
}

.landing a {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  background-color: white;
  color: var(--primary);
  font-weight: bold;
  font-size: 1.6rem;
  width: 20rem;
  margin-top: 3rem;
}

.landing a:hover{
  color: var(--primary);
}

.slogan {
  max-width: 464px;
  width: 40vw;
}

/* greetings */
#greetings .background {
  position: absolute;
  bottom: 0;
  left: -12%;
  height: 100%;
}
@media screen and (min-width: 1800px) {
  #greetings .background{ left: 0 !important; }
}
@media screen and (max-height: 760px) {
  #greetings .background{ left: 0 !important; }
}
@media screen and (max-width: 1400px) {
  #greetings .background{ left: -12% !important; }
}
@media screen and (max-width: 1100px) {
  #greetings .background{ left: -22% !important; }
}

#greetings .background-mobile {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}
@media screen and (max-width: 400px) {
  #greetings .background-mobile{ left: -12%; }
}

#greetings .background-mobile img {
  width: 100%;
  min-width: 600px;
}

#greetings .mobile-gradient {
  height: 120px;
  bottom: 0;
  position: absolute;
  left: 0;
  right: -12%;
  background-image: -webkit-gradient( linear, left top, left bottom, from(transparent), to(var(--secondary)));
  background-image: linear-gradient( transparent, var(--secondary));
}

#greetings .description {
  width: 50%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#greetings{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .description{
    width: 100% !important;
    min-height: 80vh;
    padding-top: 320px;
  }

  #overview{ flex-direction: column; }

  #overview .texts {
    padding-right: 0 !important;
    width: 100% !important;
    margin-bottom: 3rem;
  }
}

.description img {
  margin-bottom: 3rem;
  width: 30vw;
  min-width: 200px;
  max-width: 260px;
}

.description h2 {
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 3rem;
  color: white;
}

.description p {
  font-family: 'Galano Grotesque';
  font-size: 2rem;
  margin-bottom: 0;
  color: black;
}

/* overview */
#overview{
  align-items: center;
}

#overview .texts{
  padding-right: 10%;
  width: 50%;
  height: 100%;
}

#overview .texts h1{
  font-family: 'Galano Grotesque Black';
  font-size: 5.3rem;
  line-height: 5rem;
  color: var(--primary);
  margin-bottom: 3rem;
}

#overview .texts h2{
  font-size: 2.5rem;
  color: black;
  margin-bottom: 2rem;
}

#overview .texts a {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  color: white;
  background-color: var(--primary);
  font-size: 1.6rem;
  width: 20rem;
  margin-top: 3rem;
}

#overview .cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.cards .card{
  box-shadow: 1px 1px 40px #70707029;
  border-radius: 1.7rem;
  background-color: white;
  padding: 3.5rem;
  width: 100%;
}
@media screen and (max-width: 400px) {
  .cards .card{ padding: 2rem; }
}

.card img {
  height: 6.2rem;
  width: 6.2rem;
  margin-bottom: 2rem;
}

.card h4 {
  color: var(--dark);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.card p {
  font-family: 'Galano Grotesque';
  color: var(--mild-gray);
  font-size: 1.6rem;
  margin: 0;
}

.cards .image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards .image img {
  width: 80%;
}
/* #method */
#method{
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 5%;
}

#method .how {
  width: 30vw;
  min-width: 200px;
  max-width: 260px;
}

#method .background {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 90%;
}

#method .steps{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 4rem;
}

@media screen and (max-width: 600px) {
  #method .steps{ 
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr 1fr; 
  }

  #method .background { height: 50%;}

  #method h2 { max-width: unset !important; }

  #method .steps .step {
    grid-template-areas:
      "number title"
      "text text";
  }
}

#method .steps .step {
  background-color: #FFFFFF90;
  box-shadow: 1px 1px 40px #70707029;
  border-radius: 2.3rem;
  z-index: 1;
  padding: 3.5rem;
  display: grid;
  grid-template-areas:
    "number"
    "title"
    "text";
  grid-template-columns: auto 1fr;
  max-width: 40rem;
  margin: 0 auto;
}

#method h2, #method h3{
  font-size: 2.5rem;
  margin: 0;
  z-index: 2;
  line-height: 3rem;
}

#method h2{
  margin: 2rem 0 4rem 0;
  max-width: 50vw;
}
@media screen and (max-width: 750px) {
  #method h2 { max-width: 30vw; }
}

#method h3{
  color: white;
  width: 100%;
  text-align: center;
}

#method .step h1{
  height: 6.2rem;
  width: 6.2rem;
  border-radius: 50%;
  color: white;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 6rem;
  line-height: 6.2rem;
  margin-right: 3rem;
  grid-area: number;
}

#method .step h3{
  font-size: 2rem;
  color: var(--primary);
  text-align: left;
  margin-bottom: 1.5rem;
  line-height: 2.2rem;
  grid-area: title;
}

#method .step p{
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: black;
  margin: 0;
  font-family: 'Galano Grotesque';
  grid-area: text;
}
#comments {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 5%;
}

#comments .header {
    align-self: center;
    padding-top: 6rem;
}

#comments .title {
  width: 30vw;
  min-width: 350px;
  max-width: 380px;
}

#comments .steps {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 14rem;
}

@media screen and (max-width: 600px) {
  #comments .steps{ 
    margin-top: 4rem; 
    margin-bottom: 9rem;
    
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr 1fr;
  }

  #comments .steps .step p{
    height: 110px;
  }
  
#comments .title {
    min-width: 270px;
  }
  #comments .header {
    padding-top: 6rem;
    }
}

@media screen and (max-width: 800px) {      
  #comments .header {
    padding-top: 12rem;
    }
  }
@media screen and (min-width: 1400px) {
    
  #comments .header {
    padding-top: 2rem;
    }
  }
  
#comments .steps .step {
  background-color: #FFFFFF90;
  box-shadow: 1px 1px 40px #70707029;
  border-radius: 2.3rem;
  padding: 3.5rem;
  display: grid;
  grid-template-areas:
    "comment"
    "name"
    "location";
  grid-template-columns: auto 1fr;
  max-width: 40rem;
  margin: 0 auto;
}

#comments h2, #comments h3{
  font-size: 2.5rem;
  margin: 0;
  z-index: 2;
  line-height: 3rem;
}

#comments h2{
  margin: 2rem 0 4rem 0;
  max-width: 50vw;
}
@media screen and (max-width: 750px) {
  #comments h2 { max-width: 30vw; }
}

#comments .step h3{
  font-size: 2rem;
  color: var(--primary);
  text-align: left;
  margin-bottom: 3px;
  line-height: 2.2rem;
  grid-area: name;
}

#comments .step p{
  font-size: 1.8rem;
  line-height: 2.2rem;
  height: 170px;
  margin-bottom: 15px;
  color: black;
  margin: 0;
  font-family: 'Galano Grotesque';
  grid-area: comment;
}

#comments .step h4 {
    color: var(--dark);
    font-size: 1.6rem;
    grid-area: location;
  }
.copyright {
  height: 6rem;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  padding: 0 2rem;
}

.copyright h4{
  color: var(--medium-gray);
  margin: 0;
  font-size: 1.3rem;
}

.info{
  padding: 70px;
  display: flex;
  flex-direction: column;
  font-family: 'Galano Grotesque Semi Bold';
}
@media screen and (max-width: 900px) {
  .info{ 
    padding: 40px 20px;
  }
}
@media screen and (max-width: 400px) {
  .info{ 
    grid-template-columns: 1fr; 
  }
}

.info .busines{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .info .busines{
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .info .busines{
    flex-direction: column;
    justify-content: space-between;
  }

  .info article h3 {
    font-size: 4rem !important;
  }
  
  .info article h4 {
    font-size: 2rem !important;
  }
  
  .info article h5 {
    font-size: 1.8rem !important;
  }
}

.info article h3 {
  font-size: 3rem;
  color: var(--primary);
  margin: 0;
  font-family: "Galano Grotesque Semi Bold";
}

.info article h4 {
  font-size: 1.8rem;
  color: black;
  margin-bottom: 4rem;
}

.info article h5 {
  font-size: 1.4rem;
  color: var(--dark-gray);
  margin-bottom: 1rem;
}

.info article h5, .info .social h5{
  font-size: 1.4rem;
  color: var(--dark-gray);
  margin-bottom: 1rem;
}

.info article p {
  font-family: 'Galano Grotesque';
  align-items: flex-start;
  color: black;
}

.info article p img {
  margin-top: 0.4rem;
  width: 2rem;
  height: 1.6rem;
  margin-right: 1rem;
}

.info .social {
  display: flex;
  flex-direction: column;
}

.info .social .buttons{
  display: flex;
}

.info .social .buttons a:not(:last-child){
  margin-right: 2rem;
}

.info .social .buttons img{
  width: 30px;
  height: 30px;
}

.custom-modal .ant-modal-content{
  border-radius: 1rem;
}

.custom-modal .ant-modal-header{
  border: none;
  padding: 3rem 3rem 0 3rem;
  border-radius: 1rem;
}

.custom-modal .ant-modal-title{
  color: var(--primary);
  font-size: 3rem;
  font-family: "Galano Grotesque Semi Bold";
  line-height: 3rem;
}

.custom-modal .ant-modal-body{
  padding: 3rem;
}

.custom-modal .ant-modal-body h3 {
  margin: 0;
  font-size: 2rem;
  font-family: "Galano Grotesque Semi Bold";
}

.custom-modal .ant-modal-body p {
  font-size: 1.6rem;
  font-family: "Galano Grotesque";
}

.custom-modal .ant-modal-footer{
  display: none;
}

.copyright .terms-and-policy {
  cursor: pointer;
  width: 50%;
  justify-content: center;
  display: flex;
  margin-left: auto;
}

.company-info p{
  color: #6F6F6F !important;
  font-size: 12px;
}

.busines {
  margin-bottom: 3rem;
}

.lock {
  margin-bottom: 3rem;
}
.first-stage{
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sliders {
  display: flex;
}
@media screen and (max-width: 420px) {
  .sliders {
    flex-direction: column;
  }

  .sliders .slider{
    width: unset;
  }
}

.first-stage h1 {
  font-family: 'Galano Grotesque Semi Bold';
  font-size: 5rem;
  line-height: 5rem;
  color: white;
  height: 30%;
  display: flex;
  align-items: center;
}

.slider {
  width: 50%;
  margin: 2rem;
  display: flex;
  flex-direction: column;
}

.slider .ant-slider-track {
  height: 8px;
  border-radius: 6px;
  background-color: var(--secondary) !important;
  top: 2px;
}

.slider .ant-slider-handle {
  width: 24px;
  height: 24px;
  margin-top: -10px;
  border: none;
  background-color: var(--secondary) !important;
  box-shadow: none !important;
}

.slider .ant-slider-rail{
  background-color: white !important;
  height: 8px;
  top: 2px;
  border-radius: 6px;
  opacity: 0.5;
}

.slider .value{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.slider .value > h3{
  margin: 0;
  font-size: 2rem;
  color: white;
  opacity: 0.5;
  line-height: 3rem;
  margin-right: 1rem;
  padding-bottom: 0.4rem;
}

@media screen and (max-width: 600px) {
  .slider .value > h3{ padding-bottom: 0; }
}

.slider h2{
  margin: 0;
  font-size: 4rem;
  color: white;
  line-height: 4rem;
}

.slider .minmax{
  display: flex;
  justify-content: space-between;
}

.slider .max, .slider .min {
  margin: 0;
  font-size: 1.2rem;
  color: white;
  opacity: 0.5;
  font-family: "Galano Grotesque";
}

.first-stage .card {
  box-shadow: 1px 1px 40px 1px #00000050;
  background-color: white;
  border-radius: 2.3rem;
  padding: 3.5rem;
  display: flex;
  margin-bottom: 4rem;
}
@media screen and (max-width: 500px) {
  .first-stage .card {
    flex-direction: column;
  }

  .first-stage .card .summary{
    width: unset !important;
    padding-right: 0 !important;
  }
  
  .first-stage .card .separator {
    border-top: 1px solid var(--light-gray);
    margin: 1.4rem;
  }

  .first-stage .card .confirm {
    width: unset !important;
    padding-left: 0 !important;
    margin: auto;
  }
}

.first-stage .card .separator {
  border-left: 1px solid var(--light-gray);
  margin: 1.4rem 0;
}

.first-stage .card .summary {
  width: 55%;
  padding-right: 10rem;
}

.first-stage .card .summary h1{
  color: var(--primary);
  font-size: 4rem;
  margin-bottom: 1rem;
}

.first-stage .card .summary h2{
  font-size: 2rem;
  margin: 0;
}

.first-stage .card .summary h6 {
  font-size: 1.4rem;
  color: var(--dark-gray);
  font-family: "Galano Grotesque";
  margin: 0;
}

.first-stage .card .confirm {
  width: 45%;
  padding-left: 5rem;
}

.first-stage .card .confirm h2 {
  font-size: 2rem;
  margin: 0;
}

.first-stage .card .confirm h3 {
  font-size: 1.4rem;
  font-family: "Galano Grotesque";
  color: var(--dark-gray);
  margin-bottom: 2rem;
}

.first-stage .confirm .next-button {
  cursor: pointer;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  color: white;
  background-color: var(--primary);
  font-size: 1.4rem;
  width: 18rem;
  font-family: "Galano Grotesque";
}

.first-stage h6 {
  font-size: 1.4rem;
  color: white;
  opacity: 0.5;
  font-family: "Galano Grotesque";
  margin: 0;
}
.company h3{
  color: #FFFFFF;
  font-family: "Galano Grotesque";
  opacity: 0.5;
  font-size: 1.4rem;
  margin-top: 1rem;
}

.company h2 {
  margin-top: 5rem;
  margin-bottom: 0;
  color: white;
  font-size: 3rem;
}

.company .error {
    position: absolute;
    max-width: 87rem;
}

.company .error .arrow-up {
    position: relative;
    left: 16px;
    margin-top: 1rem;
    width: 0; 
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    
    border-bottom: 4px solid rgba(255, 255, 255, 0.75);

}
.company .error .error-message {
  background-color: rgba(255, 255, 255, 0.95);
  color: #FE0100;
  font-family: "Galano Grotesque";
  font-size: 1.8rem;
  border-radius: 4px;
  padding: 1rem;
}
.confirm h2 {
  color: black;
  font-size: 1.8rem;
}

.confirm .card {
  margin-top: 5rem;
  box-shadow: 1px 1px 40px 1px #00000050;
  background-color: white;
  border-radius: 2.3rem;
  padding: 3.5rem;
  display: flex;
  margin-bottom: 4rem;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .confirm .card {
    flex-direction: column;
  }

  .confirm .card .summary{
    width: unset !important;
    padding-right: 0 !important;
    border-bottom: 1px solid var(--smoke-gray);
    margin-bottom: 2rem;
  }

  .confim .card .confirm {
    width: unset !important;
    padding-left: 0 !important;
    margin: auto;
  }

  .confirm .card .installments {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.confirm .card .data {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.confirm .card .data h5, .confirm .card .data h4 {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: var(--primary);
  padding: 1rem 0;
}

.confirm .card .data h5:not(:last-of-type), .confirm .card .data h4:not(:last-of-type){
  border-bottom: 1px solid var(--smoke-gray);
}

.confirm .card .data h5 {
  justify-content: flex-end;
  padding-right: 2rem;
  font-size: 1.6rem;
  color: black;
  font-family: "Galano Grotesque";
}

.confirm .card .summary h3 {
  font-size: 2.4rem;
}

.confirm .card .installments {
  width: 100%;
  border-bottom: 1px solid var(--smoke-gray);
}

.confirm .card .installments h5 {
  font-size: 1.6rem;
  font-family: "Galano Grotesque";
  color: black;
  margin-bottom: 0.5rem;
}

.confirm .card .installments h4 {
  color: var(--primary);
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

.confirm .card .observation{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.confirm .card .observation p {
  margin-top: 2rem;
  font-size: 1.2rem;
  max-width: 250px;
}

.confirm .check{
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.confirm .check h3{
  margin-bottom: 0;
  margin-left: 1.6rem;
  font-family: "Galano Grotesque";
  color: white;
  font-size: 1.6rem;
}

.confirm .check h3 span{
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.done .form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.done .form-title img {
  height: 16rem;
  width: 16rem;
  margin-bottom: 2rem;
}

.done h2 {
  font-size: 2.5rem;
  text-align: center;
}
/* borrow */
#borrow{
  display: grid !important;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
}

#borrow .quero-emprestimo {
  width: 30vw;
  margin-right: auto;
  min-width: 200px;
  max-width: 260px;
  margin-bottom: 2rem;
}

#borrow .background {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30vw;
}

#borrow .form-container {
  width: 100%;
  max-width: 750px;
  z-index: 2;
  height: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  #borrow .form-container{ width: 70vw; }
}
@media screen and (max-width: 800px) {
  #borrow .form-container{ width: 90vw; }

  #borrow .background {
    height: unset;
    width: 50vw;
  }
}

.form-container .step-wrapper {
  padding: 0 10px;
}

.form-container .steps{
  display: flex;
  justify-content: space-between;
  position: relative;
  border-top: 1px dashed #960038;
  margin: 0 -20px;
  width: calc(100% + 40px);
}

.form-container .steps .step{
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  height: 20px;
  width: 40px;
  display: flex;
  justify-content: center;
  background-color: var(--primary);
}

.form-title h1{
  font-size: 5rem;
  line-height: 5rem;
  color: white;
}

.form-title {
  position: relative;
  width: 50rem;
  max-width: 90vw;
}

.form-title .go-back{
  position: absolute;
  left: calc(-24px - 10%);
  width: 24px;
  top: 2.4rem;
  cursor: pointer;
}
@media screen and (max-width: 800px){
  .form-title{
    margin-top: 3rem;
  }

  .form-title .go-back{
    top: -2.4rem;
    left: 0;
  }
}
@font-face {
  font-family: 'Galano Grotesque';
  src: url(/static/media/GalanoGrotesque.2ebcbbd8.ttf);
}

@font-face {
  font-family: 'Galano Grotesque Black';
  src: url(/static/media/GalanoGrotesqueBlack.49d4c500.ttf);
}

@font-face {
  font-family: 'Galano Grotesque Semi Bold';
  src: url(/static/media/GalanoGrotesqueSemiBold.e68e63e8.ttf);
}

@font-face {
  font-family: 'Galano Grotesque Medium';
  src: url(/static/media/GalanoGrotesqueMedium.45137101.ttf);
}

/* Generated by Glyphter (http://www.glyphter.com) on  Thu Dec 19 2019*/
@font-face {
  font-family: 'Glyphter';
  src: url(/static/media/Glyphter.19419252.ttf);
}

[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-ic_logo_giv:before{content:'\0041';}

:root {
  --primary: #E31250;
  --secondary: #28B595;
  --beige: #ECECDF;
  --ligth: #F6F9FB;
  --dark: #3A364E;
  --dark-gray: #6F6F6F;
  --medium-gray: #95A1AC;
  --mild-gray: #848484;
  --light-gray: #EFEFE3;
  --smoke-gray: #BFBFBF;
}

html { 
  font-size: 62.5%; 
}
@media screen and (max-width: 1600px) {
  html{ font-size: 56.25%; }
}
@media screen and (max-width: 900px) {
  html{ font-size: 50% }
}
@media screen and (max-width: 600px) {
  html{ font-size: 45%; }
}

/* layout */
body{
  overflow-x: hidden;
  overflow-y: scroll;
}

section{
  min-height: 90vh;
  position: relative;
  display: flex !important;
  font-family: 'Galano Grotesque Semi Bold';
}
section, .info{
  padding: 5% 20%;
}

@media screen and (max-width: 2300px) {
  section, .info{ padding: 5% 15%; }
}
@media screen and (max-width: 1300px) {
  section, .info{ padding: 5% 10%; }
}
@media screen and (max-width: 1000px) {
  section, .info{ padding: 5% 5%; }
}

#home, #borrow{
  background-color: #E31250;
  background-color: var(--primary);
}

#overview, #comments{
  background-color: #F6F9FB;
  background-color: var(--ligth);
}

#greetings, #method{
  background-color: #28B595;
  background-color: var(--secondary);
}

#home {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-back-top {
  box-shadow: 1px 1px 40px #00000050;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
}

.custom-select .ant-select-selection, .custom-input .ant-input {
  width: 100%;
  font-size: 2.5rem;
  height: 4.5rem;
  border: none;
  border-radius: unset;
  box-shadow: none !important;
  background: transparent;
  border-bottom: 2px solid #ffffff7F !important;
  color: white;
}

.custom-datepicker .ant-input{
    width: 100%;
    font-size: 2.5rem;
    height: 4.5rem;
    max-width: 280px;
    border: none;
    border-radius: unset;
    box-shadow: none !important;
    background: transparent;
    border-bottom: 2px solid #ffffff7F !important;
    color: white;
}

.custom-datepicker {
    margin-top: 5rem !important;
}

.ant-calendar-picker-container .ant-calendar {
    border: unset;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap {
    height: 4.5rem;
    background-color: #E31250;
    background-color: var(--primary);
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input {
    background-color: #E31250;
    background-color: var(--primary);
    color: #ffffff7F;
    font-size: 20px;
    height: 27px;
    font-weight: bold;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input::-webkit-input-placeholder {
    background-color: #E31250;
    background-color: var(--primary);
    color: white;
    opacity: 0.5;
    font-weight: bold;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input::-moz-placeholder {
    background-color: #E31250;
    background-color: var(--primary);
    color: white;
    opacity: 0.5;
    font-weight: bold;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input:-ms-input-placeholder {
    background-color: #E31250;
    background-color: var(--primary);
    color: white;
    opacity: 0.5;
    font-weight: bold;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input::-ms-input-placeholder {
    background-color: #E31250;
    background-color: var(--primary);
    color: white;
    opacity: 0.5;
    font-weight: bold;
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-panel .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input::placeholder {
    background-color: #E31250;
    background-color: var(--primary);
    color: white;
    opacity: 0.5;
    font-weight: bold;
}

.ant-calendar-selected-day .ant-calendar-date {
    background: #bae7ff;
}

.ant-calendar-picker .ant-calendar-picker-icon {
    color: white;
}
.custom-datepicker { 
    position: relative; 
    display: flex;
    flex-direction: column;
}
  
.custom-datepicker h4 {
    position: absolute;
    bottom: 4rem;
    font-size: 1.6rem;
    color: white;
    opacity: 0;
    margin: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}
  
.custom-datepicker h4.visible{
    opacity: 0.5;
}
  
.custom-datepicker .ant-input { 
    padding-bottom: 1.5rem; 
    padding-left: 0;
}
  
.custom-datepicker .ant-input::-webkit-input-placeholder {
    color: white;
    opacity: 0.5;
}
  
.custom-datepicker .ant-input::-moz-placeholder {
    color: white;
    opacity: 0.5;
}
  
.custom-datepicker .ant-input:-ms-input-placeholder {
    color: white;
    opacity: 0.5;
}
  
.custom-datepicker .ant-input::-ms-input-placeholder {
    color: white;
    opacity: 0.5;
}
  
.custom-datepicker .ant-input::placeholder {
    color: white;
    opacity: 0.5;
}

.custom-select .ant-select-selection__placeholder {
  height: 3rem;
  color: white;
  opacity: 0.5;
}

.custom-select {
  max-width: 500px;
}

.custom-button{
  background-color: white;
  border-radius: 2.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #E31250;
  color: var(--primary);
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.custom-button:disabled{
  cursor: default;
  background-color: #ffffff7F;
}

.custom-select, .custom-input, .custom-button {
  margin-top: 5rem !important;
  width: 100%;
}

.ant-select-dropdown-menu-item{
  font-family: "Galano Grotesque";
  font-size: 2rem !important;
  line-height: 3rem !important;
}

.ant-select-dropdown-menu-item-active{
  background-color: #E312507f !important;
}

.custom-input { 
  position: relative; 
  display: flex;
  flex-direction: column;
}

.custom-input h4 {
  position: absolute;
  bottom: 4rem;
  font-size: 1.6rem;
  color: white;
  opacity: 0;
  margin: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.custom-input h4.visible{
  opacity: 0.5;
}

.custom-input .ant-input { 
  padding-bottom: 1.5rem; 
  padding-left: 0;
}

.custom-input .ant-input::-webkit-input-placeholder {
  color: white;
  opacity: 0.5;
}

.custom-input .ant-input::-moz-placeholder {
  color: white;
  opacity: 0.5;
}

.custom-input .ant-input:-ms-input-placeholder {
  color: white;
  opacity: 0.5;
}

.custom-input .ant-input::-ms-input-placeholder {
  color: white;
  opacity: 0.5;
}

.custom-input .ant-input::placeholder {
  color: white;
  opacity: 0.5;
}

.ant-tooltip {
  max-width: 100% !important;
}

.ant-tooltip-arrow::before {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.ant-tooltip-inner {
  color: #FE0100 !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  font-family: "Galano Grotesque";
  font-size: 1.5rem;
  padding: 1rem !important;
  padding-bottom: 1.2rem !important;
}

.ant-tooltip-content {
  border-radius: 0.5rem;
}

.ant-select-selection__rendered{
  margin: 0 !important;
}

.check .ant-checkbox-inner{
  --antd-wave-shadow-color: white;
  background-color: #E31250;
  background-color: var(--primary);
  border: 2px solid white !important;
  width: 3.2rem;
  height: 3.2rem;
}

.check .ant-checkbox-checked .ant-checkbox-inner::after {
  border-radius: 0 0 3px 0;
  border-width: 0.4rem;
  border-color: #E31250;
  border-color: var(--primary);
}

.check .ant-checkbox-checked .ant-checkbox-inner{
  background-color: white;
  border-color: #E31250;
  border-color: var(--primary);
}

.check .ant-checkbox-inner::after{
  width: 1.2rem;
  height: 2.1rem;
}

.check .ant-checkbox-checked::after {
  border: none;
}
.policy-and-terms{
  font-family: 'Montserrat';
  width: 60vw !important;
}
@media screen and (max-width: 2300px) {
  .policy-and-terms{ width: 70vw !important; }
}
@media screen and (max-width: 1300px) {
  .policy-and-terms{ width: 80vw !important; }
}
@media screen and (max-width: 1000px) {
  .policy-and-terms{ width: 90vw !important; }
}

.policy-and-terms .ant-modal-footer{
  display: none;
}

.policy-and-terms h1 {
  text-align: center;
}

.policy-and-terms h1,
.policy-and-terms h2,
.policy-and-terms h3 {
  font-weight: bold;
}

.policy-and-terms .custom-button{
  font-family: "Galano Grotesque";
  max-width: 20rem;
  background-color: var(--primary);
  color: white;
  margin: 0 auto;
}
