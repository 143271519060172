/* borrow */
#borrow{
  display: grid !important;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
}

#borrow .quero-emprestimo {
  width: 30vw;
  margin-right: auto;
  min-width: 200px;
  max-width: 260px;
  margin-bottom: 2rem;
}

#borrow .background {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30vw;
}

#borrow .form-container {
  width: 100%;
  max-width: 750px;
  z-index: 2;
  height: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  #borrow .form-container{ width: 70vw; }
}
@media screen and (max-width: 800px) {
  #borrow .form-container{ width: 90vw; }

  #borrow .background {
    height: unset;
    width: 50vw;
  }
}

.form-container .step-wrapper {
  padding: 0 10px;
}

.form-container .steps{
  display: flex;
  justify-content: space-between;
  position: relative;
  border-top: 1px dashed #960038;
  margin: 0 -20px;
  width: calc(100% + 40px);
}

.form-container .steps .step{
  transform: translate(0, -50%);
  height: 20px;
  width: 40px;
  display: flex;
  justify-content: center;
  background-color: var(--primary);
}

.form-title h1{
  font-size: 5rem;
  line-height: 5rem;
  color: white;
}

.form-title {
  position: relative;
  width: 50rem;
  max-width: 90vw;
}

.form-title .go-back{
  position: absolute;
  left: calc(-24px - 10%);
  width: 24px;
  top: 2.4rem;
  cursor: pointer;
}
@media screen and (max-width: 800px){
  .form-title{
    margin-top: 3rem;
  }

  .form-title .go-back{
    top: -2.4rem;
    left: 0;
  }
}