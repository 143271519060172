.first-stage{
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sliders {
  display: flex;
}
@media screen and (max-width: 420px) {
  .sliders {
    flex-direction: column;
  }

  .sliders .slider{
    width: unset;
  }
}

.first-stage h1 {
  font-family: 'Galano Grotesque Semi Bold';
  font-size: 5rem;
  line-height: 5rem;
  color: white;
  height: 30%;
  display: flex;
  align-items: center;
}

.slider {
  width: 50%;
  margin: 2rem;
  display: flex;
  flex-direction: column;
}

.slider .ant-slider-track {
  height: 8px;
  border-radius: 6px;
  background-color: var(--secondary) !important;
  top: 2px;
}

.slider .ant-slider-handle {
  width: 24px;
  height: 24px;
  margin-top: -10px;
  border: none;
  background-color: var(--secondary) !important;
  box-shadow: none !important;
}

.slider .ant-slider-rail{
  background-color: white !important;
  height: 8px;
  top: 2px;
  border-radius: 6px;
  opacity: 0.5;
}

.slider .value{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.slider .value > h3{
  margin: 0;
  font-size: 2rem;
  color: white;
  opacity: 0.5;
  line-height: 3rem;
  margin-right: 1rem;
  padding-bottom: 0.4rem;
}

@media screen and (max-width: 600px) {
  .slider .value > h3{ padding-bottom: 0; }
}

.slider h2{
  margin: 0;
  font-size: 4rem;
  color: white;
  line-height: 4rem;
}

.slider .minmax{
  display: flex;
  justify-content: space-between;
}

.slider .max, .slider .min {
  margin: 0;
  font-size: 1.2rem;
  color: white;
  opacity: 0.5;
  font-family: "Galano Grotesque";
}

.first-stage .card {
  box-shadow: 1px 1px 40px 1px #00000050;
  background-color: white;
  border-radius: 2.3rem;
  padding: 3.5rem;
  display: flex;
  margin-bottom: 4rem;
}
@media screen and (max-width: 500px) {
  .first-stage .card {
    flex-direction: column;
  }

  .first-stage .card .summary{
    width: unset !important;
    padding-right: 0 !important;
  }
  
  .first-stage .card .separator {
    border-top: 1px solid var(--light-gray);
    margin: 1.4rem;
  }

  .first-stage .card .confirm {
    width: unset !important;
    padding-left: 0 !important;
    margin: auto;
  }
}

.first-stage .card .separator {
  border-left: 1px solid var(--light-gray);
  margin: 1.4rem 0;
}

.first-stage .card .summary {
  width: 55%;
  padding-right: 10rem;
}

.first-stage .card .summary h1{
  color: var(--primary);
  font-size: 4rem;
  margin-bottom: 1rem;
}

.first-stage .card .summary h2{
  font-size: 2rem;
  margin: 0;
}

.first-stage .card .summary h6 {
  font-size: 1.4rem;
  color: var(--dark-gray);
  font-family: "Galano Grotesque";
  margin: 0;
}

.first-stage .card .confirm {
  width: 45%;
  padding-left: 5rem;
}

.first-stage .card .confirm h2 {
  font-size: 2rem;
  margin: 0;
}

.first-stage .card .confirm h3 {
  font-size: 1.4rem;
  font-family: "Galano Grotesque";
  color: var(--dark-gray);
  margin-bottom: 2rem;
}

.first-stage .confirm .next-button {
  cursor: pointer;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  color: white;
  background-color: var(--primary);
  font-size: 1.4rem;
  width: 18rem;
  font-family: "Galano Grotesque";
}

.first-stage h6 {
  font-size: 1.4rem;
  color: white;
  opacity: 0.5;
  font-family: "Galano Grotesque";
  margin: 0;
}